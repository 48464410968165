<div [ngClass]="appliedClasses()" class="flex items-center gap-2 mb-4">
  <p class="font-medium" [ngClass]="headingClass()">{{ heading() | translate }}</p>
  @if (tooltip()) {
    <est-tooltip [tooltip]="tooltip()!"></est-tooltip>
  }
</div>

<p [ngClass]="subheadingAppliedClasses()" class="text-sm font-light xl:max-w-[43.75rem]">
  {{ subheading() ?? "" | translate }}
</p>
